import axios from "axios";
export const validateOtpApi = async (code, phoneNumber, otp) => {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  console.log(BASE_URL);

  try {
    const response = await axios.post(`${BASE_URL}/validateotp/`, {
      phone: code + phoneNumber,
      otp: otp,
    });
    return { result: response.data };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};
