export const concatenateAddress = (address) => {
    const concatenatedAddress = [
        address.doorno,
        address.addressline1,
        address.addressline2,
        address.addressline3,
        address.city1,
        address.city2,
        address.city3,
        address.pinorzip,
    ]
        .filter((part) => part)
        .join(", ");

    return concatenatedAddress;
};