import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../api/baseApi";

export const listOrders = createAsyncThunk("orders/listOrders", async (id) => {
  const response = await axios.get(`${BASE_URL}/vieworder/${id}`);
  return response.data;
});

export const postMessage = createAsyncThunk(
  "orders/postMessage",
  async (postData) => {
    const response = await axios.post(`${BASE_URL}/postMessage/`, {
      message: postData.msg,
      order_id: postData.orderid,
      direction: "OUT",
      message_type: "TEXT",
      status: "sent",
      sender_type: "user/store",
    });
    return response.data;
  }
);

const initialState = {
  orders: [],
  loading: false,
  error: false,
};

export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrders: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listOrders.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listOrders.fulfilled, (state, action) => {
        state.loading = false;
        state.orders = action.payload;
      })
      .addCase(listOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(postMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearOrders } = orderSlice.actions;
export default orderSlice.reducer;
