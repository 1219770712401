import {
  Button,
  List,
  ListItem,
  TableFooter,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./ItemList.css";
import { useMediaQuery } from "react-responsive";
import styled from "@emotion/styled";
import { getCurrencySymbol } from "../../../configData/Currency";
import { useSelector } from "react-redux";
import { ArrowBack, ArrowForward, BackHand } from "@mui/icons-material";

function ItemList({ orders, totalItems, totalPrice }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const isDesktopOrMobile = useMediaQuery({
    query: "(min-width: 500px)",
  });

  const TableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #ffffff;
    }
    &:nth-of-type(even) {
      background-color: #eaecf0;
    }
  `;

  useEffect(() => {
    setPage(0);
  }, [orders]);

  return (
    <Paper
      style={{
        borderRadius: "12px",
        maxHeight: "600px",
        width: "100%",
      }}
    >
      <TableContainer style={{ maxHeight: "550px", overflowX: "scroll" }}>
        <Table aria-label="sticky table">
          <TableHead
            style={{
              height: "44px",
              borderRadius: "100px",
            }}
          >
            <TableRow>
              <TableCell sx={{ padding: 0, textAlign: "center" }} align="left">
                <h1 className="w-16">S.no</h1>
              </TableCell>
              {isDesktopOrMobile && (
                <TableCell
                  sx={{ padding: 0, textAlign: "center" }}
                  className="cel"
                >
                  <h1 className="w-40">Product Code</h1>
                </TableCell>
              )}
              <TableCell sx={{ padding: 0, textAlign: "center" }} align="left">
                <h1 className="w-40">Product Name</h1>
              </TableCell>
              <TableCell sx={{ padding: 0, textAlign: "center" }} align="left">
                <h1 className="w-20">Qty</h1>
              </TableCell>
              <TableCell sx={{ padding: 0, textAlign: "center" }} align="left">
                <h1 className="w-24">
                  Price ({getCurrencySymbol(orders[0].products[0].currencycode)}
                  )
                </h1>
              </TableCell>
              <TableCell sx={{ padding: 0, textAlign: "center" }} align="left">
                <h1 className="w-24">Sub Total</h1>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders[0].products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRowStyled key={index} sx={{ height: "40px" }}>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: 0, textAlign: "center" }}
                    >
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    {isDesktopOrMobile && (
                      <TableCell
                        component="th"
                        scope="row"
                        className="cel"
                        sx={{ padding: 0, textAlign: "center" }}
                      >
                        {item.productcode}
                      </TableCell>
                    )}
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: 0, textAlign: "center" }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: 0, textAlign: "center" }}
                    >
                      {item.productquantity}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: 0, textAlign: "center" }}
                    >
                      {item.msrp}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ padding: 0, textAlign: "center" }}
                    >
                      {item.msrp * item.productquantity}
                    </TableCell>
                  </TableRowStyled>
                );
              })}
            {/* <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="left">Total Items : {totalItems}</TableCell>
              <TableCell></TableCell>
              <TableCell align="justify">Total Price : {totalPrice}</TableCell>
            </TableRow> */}
          </TableBody>
        </Table>

        {/* <TableFooter style={{ position: 'sticky' }}>

        </TableFooter> */}
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        rowsPerPage={rowsPerPage}
        component="div"
        count={orders[0].products.length}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <div className="font-montserrat flex justify-between mx-2 py-3">
        <Button
          disabled={page + 1 === 1}
          onClick={(e) => {
            handleChangePage(e, page - 1);
          }}
        >
          <ArrowBack />
        </Button>
        <h1>
          Page {page + 1} of{" "}
          {Math.ceil(orders[0].products.length / rowsPerPage)}
        </h1>
        <Button
          disabled={
            page + 1 >= Math.ceil(orders[0].products.length / rowsPerPage)
          }
          onClick={(e) => {
            handleChangePage(e, page + 1);
          }}
        >
          <ArrowForward />
        </Button>
      </div>
    </Paper>
  );
}

export default ItemList;
