import React, { useState } from "react";
import { homeScreenData } from "../../../components/common/DummyData";
import Title from "../../../components/common/Title";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/loginSlice";
import { useLocation } from "react-router-dom";

const HomeScreenHeader = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const loginSlice = useSelector((state) => state.login);
  const loginStatus = loginSlice.loginState;
  const dispatch = useDispatch();
  const handleToggle = () => {
    setIsMenuVisible(!isMenuVisible);
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  const location = useLocation();
  const currentPath = location.pathname;
  console.log(currentPath, "ahsjkdfakjsd");
  return (
    <div
      id={currentPath === "/" ? "homescreen_header" : "homescreen_header_min"}
      className=""
    >
      <div className="containers mx-auto max-w-screen-xl px-6 py-6 font-montserrat">
        <nav className=" flex items-center justify-between text-black">
          <Title />
          <div className="hidden h-10 items-center md:flex md:space-x-8">
            {currentPath === "/" && (
              <>
                <div className="group">
                  <a href="#homescreen_about">About</a>
                  <div className="nav_btn"></div>
                </div>

                <div className="group">
                  <a href="#homescreen_faq">FAQ</a>
                  <div className="nav_btn"></div>
                </div>
              </>
            )}
            {currentPath !== "/privacy" && currentPath !== "/terms" && (
              <div class="group relative inline-block">
                <a class="text-black hover:text-blue-500">Account</a>
                <div class="hidden absolute left-0 bg-white shadow-lg group-hover:block ">
                  <ul class="py-2">
                    <li>
                      <a
                        href="/unsubscribe"
                        class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                      >
                        Settings
                      </a>
                    </li>
                    <li>
                      {loginStatus ? (
                        <a
                          onClick={handleLogout}
                          className="block px-4 py-2 hover:bg-gray-100"
                        >
                          Logout
                        </a>
                      ) : (
                        <a
                          href="/login"
                          className="block px-4 py-2 hover:bg-gray-100"
                        >
                          Login
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {/* <div className="group">
              <a href="/unsubscribe">Account</a>
              <div className="nav_btn"></div>
            </div> */}
          </div>
          {currentPath !== "/privacy" && currentPath !== "/terms" && (
            <div className={`md:hidden`}>
              <button
                onClick={handleToggle}
                type="button"
                className={`hamburger z-40 block focus:outline-none md:hidden ${
                  isMenuVisible ? "open" : ""
                }`}
              >
                <span className="hamburger-top"></span>
                <span className="hamburger-middle"></span>
                <span className="hamburger-bottom"></span>
              </button>
            </div>
          )}
        </nav>

        <div
          className={`absolute bottom-0 left-0 top-0 z-10 ${
            isMenuVisible ? "flex" : "hidden"
          }  min-h-screen w-full flex-col space-y-3 self-end bg-black py-1 pl-12 pt-40 text-lg uppercase text-white`}
        >
          {currentPath === "/" && (
            <>
              <a
                href="#homescreen_about"
                onClick={handleToggle}
                className="hover:text-pink-500"
              >
                About
              </a>
              <a
                href="#homescreen_faq"
                onClick={handleToggle}
                className="hover:text-pink-500"
              >
                FAQ
              </a>
            </>
          )}
          <div class="group relative inline-block text-left">
            <a class="hover:text-pink-500">Account</a>
            <div class="hidden absolute left-0 bg-white shadow-lg group-hover:block text-left">
              <ul class="py-2">
                <li>
                  <a
                    href="/unsubscribe"
                    class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                  >
                    Settings
                  </a>
                </li>
                <li>
                  {loginStatus ? (
                    <a
                      onClick={handleLogout}
                      class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Logout
                    </a>
                  ) : (
                    <a
                      href="/login"
                      class="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      Login
                    </a>
                  )}
                </li>
              </ul>
            </div>
          </div>

          {/* {loginStatus && (
            <a href="/store/ordermanager" className="hover:text-pink-500">
              Orders
            </a>
          )}
          {loginStatus && (
            <a href="/storelist" className="hover:text-pink-500">
              Store List
            </a>
          )} */}
        </div>

        {currentPath === "/" && (
          <div className="mb-32 mt-32 max-w-lg border-2 p-4 font-sans text-4xl uppercase text-white md:m-32 md:mx-0 md:p-10 md:text-6xl">
            {homeScreenData.title}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeScreenHeader;
