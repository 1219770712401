import React from "react";
import StoreSideBar from "./components/StoreSideBar";

const StoreScreen = () => {
  const selectedStoreMenu = "";
  const handleStoreMenu = () => {};
  return (
    <div>
      <StoreSideBar
        selectedStoreMenu={selectedStoreMenu}
        handleStoreMenu={handleStoreMenu}
      />
    </div>
  );
};

export default StoreScreen;
