export const statusList = {
  Pickup: [
    {
      status: "OrderSubmittedByCustomer",
      statusMessage: "New Order",
    },
    {
      status: "OrderInProcessingByDeliveryStaff",
      statusMessage: "Started packing",
    },
    {
      status: "OrderReadyForPickup",
      statusMessage: "Ready for pickup",
    },
    {
      status: "OrderPickedUpByCustomer",
      statusMessage: "Picked up by customer",
    },
  ],
  Delivery: [
    {
      status: "OrderSubmittedByCustomer",
      statusMessage: "New Order",
    },
    {
      status: "OrderReadyForPickup",
      statusMessage: "Preparing for home delivery",
    },
    {
      status: "OrderBeingDelivered",
      statusMessage: "Out for home delivery",
    },
    {
      status: "OrderDelivered",
      statusMessage: "Delivered to customer",
    },
  ],
  Shipping: [
    {
      status: "OrderSubmittedByCustomer",
      statusMessage: "New Order",
    },
    {
      status: "OrderReadyForDelivery",
      statusMessage: "Preparing for shipping",
    },
    {
      status: "OrderBeingDelivered",
      statusMessage: "Shipping in progress",
    },
    {
      status: "OrderDelivered",
      statusMessage: "Delivered to customer",
    },
  ],
};
