// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order_list_container {
  background-color: antiquewhite;
  width: 100%;
  display: flex;
}


`, "",{"version":3,"sources":["webpack://./src/screens/Order/OrdersList.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,WAAW;EACX,aAAa;AACf","sourcesContent":[".order_list_container {\n  background-color: antiquewhite;\n  width: 100%;\n  display: flex;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
