import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ component, from }) => {
  const login = useSelector((state) => state.login);
  return login.loginState ? (
    component
  ) : (
    <Navigate to="/login" state={{ from: `/${from}` }} />
  );
};

export default RequireAuth;
