import axios from "axios";
import { BASE_URL } from "./baseApi";

export const orderStatus = async (orderid, orderStatus, userid) => {
  const response = await axios.post(`${BASE_URL}/postorderstatus/`, {
    order_id: orderid,
    order_status: orderStatus,
    user_id: userid,
  });
  return response.data;
};

export const postInvoice = async (file, id, price) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("order_id", id);
  formData.append("price", price);

  try {
    const response = await axios.post(`${BASE_URL}/postinvoice/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getOrders = async (authToken) => {
  try {
    const response = await axios.get(`${BASE_URL}/getorders/`, {
      headers: {
        Authorization: `${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error, "op");
  }
};

export const deleteMedia = async (fileName) => {
  try {
    const response = await axios.post(`${BASE_URL}/deletemedia/`, {
      fileName: fileName,
    });
    console.log(response, "delete");
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
