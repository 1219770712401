import { Button, TextField } from "@mui/material";
import {
  CheckBoxOutlineBlank,
  Done,
  DoneAll,
  ArrowBack,
  Send,
  WavingHand,
} from "@mui/icons-material";
import "./Chat.css";
import React from "react";
import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";

function Chat({ sendMessage, onChange, messages, onBack }) {
  const chatContainerRef = React.useRef(null);
  const isDesktopOrMobile = useMediaQuery({
    query: "(min-width: 500px)",
  });

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <div
      className={isDesktopOrMobile ? "chat_container" : "chat_container_mobile"}
    >
      <div className="chat_header">
        <WavingHand
          style={{
            width: "28px",
            height: "28px",
            paddingRight: "13px",
            maxRows: "8px",
            color: "#bcaf29",
          }}
        />
        <h2>Chat with customer</h2>
      </div>
      <div className="chat_contact">
        {!isDesktopOrMobile && (
          <ArrowBack
            onClick={onBack}
            style={{
              width: "28px",
              height: "28px",
              paddingRight: "13px",
              maxRows: "8px",
              color: "#bcaf29",
            }}
          />
        )}

        <img
          src={
            "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6?w=164&h=164&fit=crop&auto=format"
          }
          loading="lazy"
        />
        <h4>Wilson</h4>
      </div>
      <ul
        className={isDesktopOrMobile ? "chat_ul" : "chat_ul_mobile"}
        ref={chatContainerRef}
      >
        {messages &&
          messages.map((item, index) => {
            const time = (timestamp) => {
              const dateObject = new Date(timestamp);
              const hours = dateObject.getUTCHours() + 10; // Add 5 hours for IST (GMT + 5)
              const minutes = dateObject.getUTCMinutes() + 30; // Add 30 minutes for IST
              const adjustedHours = hours + Math.floor(minutes / 60);
              const adjustedMinutes = minutes % 60;
              const ampm = adjustedHours >= 12 ? "PM" : "AM";
              const formattedHours = String(adjustedHours % 12 || 12).padStart(
                2,
                "0"
              );
              const formattedMinutes = String(adjustedMinutes).padStart(2, "0");
              const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
              return formattedTime;
            };
            return (
              <li
                key={index}
                style={
                  item.direction === "IN"
                    ? {
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "14px",
                      }
                    : {
                        display: "flex",
                        flexDirection: "row-reverse",
                        marginBottom: "14px",
                      }
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className={
                      item.direction === "IN" ? "chat_from" : "chat_to"
                    }
                  >
                    <h5>{item.message}</h5>
                  </div>
                  <div
                    className={
                      item.direction === "IN"
                        ? "time_status_from"
                        : "time_status_to"
                    }
                  >
                    <h6>{time(item.sent_time)}</h6>
                    <h6>{item.status}</h6>
                    {item.direction !== "IN" && (
                      <DoneAll
                        color="disabled"
                        sx={{ display: "flex", alignSelf: "flex-end" }}
                      />
                    )}
                  </div>
                </div>
              </li>
            );
          })}
      </ul>
      <div
        className={
          isDesktopOrMobile
            ? "chat_container_position"
            : "chat_container_position_mobile"
        }
      >
        <TextField
          id="basic_email"
          multiline
          placeholder="Type something"
          maxRows={3}
          InputProps={{
            style: { borderRadius: 20, width: "243px" },
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
        <Button
          style={{
            backgroundColor: "#51A175",
            width: "58px",
            height: "58px",
            padding: "13px",
            borderRadius: "20px",
            color: "white",
          }}
        >
          <Send onClick={sendMessage} />
        </Button>
      </div>
    </div>
  );
}

export default Chat;
