import axios from "axios";
import { BASE_URL } from "./baseApi";

export const isNewUser = async (phoneNumber) => {
  try {
    const response = await axios.post(`${BASE_URL}/isnewuser/`, {
      phone: phoneNumber,
    });
    return { result: response.data };
  } catch (error) {
    return { error: error };
  }
};

export const getOtpApi = async (code, phoneNumber) => {
  console.log(BASE_URL);
  try {
    const response = await axios.post(`${BASE_URL}/otp/`, {
      phone: code + phoneNumber,
    });
    return { result: response.data };
  } catch (error) {
    console.log(error, "getOtpApi error");
    return { error: error };
  }
};

export const postLoginApi = async (code, phoneNumber, otp) => {
  try {
    const response = await axios.post(`${BASE_URL}/login/`, {
      phone: code + phoneNumber,
      otp: otp,
    });
    return { result: response.data };
  } catch (error) {
    console.log(error, "postLoginApi error");
    return { error: error };
  }
};

export const postPreferencesApi = async (number, preferences) => {
  try {
    const response = await axios.post(`${BASE_URL}/postPreferences/`, {
      phone: number,
      preferences: preferences,
    });

    return { result: response.data };
  } catch (error) {
    return { error: error };
  }
};
