import React from "react";
import "../Privacy/PrivacyPolicy.css";
import HomeScreenHeader from "../Home/components/HomeScreenHeader";

const TermsAndConditions = () => {
  return (
    <div className=" justify-center mx-auto flex flex-1 flex-col font-montserrat custom-heading-styles">
      <HomeScreenHeader />
      <section className="privacy">
        <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
          <div className="title text-center text-5xl font-medium">
            <p>Terms And Conditions</p>
            <hr />
          </div>
          <div className="p-6 max-w-4xl mx-auto">
            <p className="mb-4">
              Welcome to Jasmine eCommerce Solutions ("we," "our," "us").
              CartOCart is the mobile application (the "App") developed by us to
              support eCommerce solutions for small businesses. CartOCart refers
              to either the application or the Jasmine eCommerce Solutions. By
              accessing CartOCart and related services (website and
              collectively, the "Services"), you agree to comply with and be
              bound by these Terms and Conditions (the "T&C"). If you do not
              agree to these T&C, please do not use the App.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">1. Definitions</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                “User” refers to anyone who accesses or uses the App.
              </li>
              <li className="mb-2">
                “Products” refers to the grocery items available for purchase
                through the App.
              </li>
              <li className="mb-2">
                “Services” refers to the grocery delivery, pickup, and related
                services provided through the App.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">
              2. User Responsibilities
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Account Creation:</strong> To use certain features of
                the App, you may need to create an account. You agree to provide
                accurate, current, and complete information and to keep your
                account information updated.
              </li>
              {/* <li className="mb-2">
                <strong>Account Security:</strong> You are responsible for
                maintaining the confidentiality of your account and password and
                for all activities that occur under your account.
              </li> */}
              <li className="mb-2">
                <strong>Prohibited Uses:</strong> You agree not to use the App
                for any unlawful or unauthorized purposes, including but not
                limited to, attempting to gain unauthorized access to the App or
                its related systems.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">
              3. Products and Orders
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Product Information:</strong> We strive to provide
                accurate product descriptions and images. However, we do not
                guarantee the accuracy, completeness, or reliability of any
                product information.
              </li>
              <li className="mb-2">
                <strong>Availability:</strong> All products are subject to
                availability. We reserve the right to limit quantities or refuse
                orders at our discretion.
              </li>
              <li className="mb-2">
                <strong>Order Confirmation:</strong> After placing an order, you
                will receive a confirmation via WhatsApp or in-app notification.
                This confirmation does not guarantee that the products are
                available.
              </li>
              <li className="mb-2">
                <strong>Pricing and Payment:</strong> Prices are subject to
                change without notice. Payments needs to be made with the
                vendors and Jasmine eCommerce Solutions does not deal with
                payements directly.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">
              4. Delivery and Pickup
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Delivery Areas:</strong> We offer delivery services
                within specific areas. Delivery availability and times may vary
                based on your location.
              </li>
              <li className="mb-2">
                <strong>Delivery Fees:</strong> Delivery fees may apply and will
                be displayed at checkout.
              </li>
              <li className="mb-2">
                <strong>Pickup Option:</strong> If available, you may choose to
                pick up your order from a designated location. Orders must be
                picked up within the specified time frame.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">5. Privacy Policy</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Data Collection:</strong> We collect personal
                information to process orders, improve our services, and
                communicate with you. For details on how we use and protect your
                data, please review our{" "}
                <a
                  href="/privacy"
                  // target="_blank"
                  // rel="noopener noreferrer"
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Privacy Policy
                </a>
                .
              </li>
              <li className="mb-2">
                <strong>Data Sharing:</strong> We do not share your personal
                information with third parties except as necessary to fulfill
                orders or as required by law.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">6. User Content</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Reviews and Feedback:</strong> You may leave reviews and
                feedback on products. By submitting content, you grant us a
                non-exclusive, royalty-free, worldwide license to use,
                reproduce, and display such content.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">
              7. Limitation of Liability
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Disclaimer:</strong> The App and Services are provided
                "as is" without warranties of any kind. We do not warrant that
                the App will be uninterrupted or error-free.
              </li>
              <li className="mb-2">
                <strong>Limitation:</strong> Our liability for any claim related
                to the App or Services is limited to the amount you paid for the
                products in question.
              </li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 mb-2">8. Changes to T&C</h2>
            <p className="mb-4">
              We may update these T&C from time to time. We will notify you of
              any significant changes by posting the new T&C on the App or via
              SMS WhatsApp. Your continued use of the App after changes
              signifies your acceptance of the updated T&C.
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">9. Termination</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Termination by us:</strong> We reserve the right to
                suspend or terminate your access to the App if you violate these
                T&C or for any other reason at our discretion.
              </li>
              <li className="mb-2">
                <strong>Effect of Termination:</strong> Upon termination, you
                will lose access to your account and any data associated with
                it.
              </li>
            </ul>
            {/* <h2 className="text-2xl font-bold mt-6 mb-2">
              10. Dispute Resolution
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Governing Law:</strong> These T&C are governed by the
                laws of [Your Jurisdiction].
              </li>
              <li className="mb-2">
                <strong>Dispute Resolution:</strong> Any disputes arising under
                these T&C will be resolved through binding arbitration in [Your
                Jurisdiction].
              </li>
            </ul> */}
            <h2 className="text-2xl font-bold mt-6 mb-2">
              10. Contact Information
            </h2>
            <p>
              Email:{" "}
              <a
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                href="mailto:contact@jaseminegrocery.com"
              >
                contact@jaseminegrocery.com
              </a>
            </p>
            <p>Jasmine eCommerce Solutions, Inc.</p>
            <p>
              <a
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                href="https://jasminegrocery.com"
              >
                https://jasminegrocery.com
              </a>
            </p>
            <h2 className="text-2xl font-bold mt-6 mb-2">11. Miscellaneous</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                <strong>Severability:</strong> If any provision of these T&C is
                found to be invalid or unenforceable, the remaining provisions
                will continue in full force and effect.
              </li>
              <li className="mb-2">
                <strong>Entire Agreement:</strong> These T&C constitute the
                entire agreement between you and us regarding your use of the
                App and Services.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
