const currencySymbols = {
  USD: "$",
  INR: "₹",
};

export function getCurrencySymbol(currency) {
  return currencySymbols[currency] || "ERR: Currency.js"; // Replace "DefaultSymbol" with a default value
}

// Usage
const currencyCode = "USD"; // Replace with the currency code you have
const symbol = getCurrencySymbol(currencyCode);
