import { Close } from "@mui/icons-material";
import { Alert, Button, IconButton, Snackbar } from "@mui/material";
import React from "react";

function ToastAlert({ alert, handleClose, autoHideDuration, severity }) {
  return (
    <div>
      <Snackbar
        open={alert}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        message={alert}
      >
        <Alert
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alert}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ToastAlert;
