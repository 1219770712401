import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getOrders } from "../../../api/orderApi";
import Loader from "../../Order/components/Loader";
import moment from "moment";

const OrderListNew = () => {
  const loginSlice = useSelector((state) => state.login);
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState([]);

  const getOrderList = async () => {
    const ordersList = await getOrders(loginSlice.authtoken);
    setOrderList(ordersList);
    setIsLoading(false);
  };
console.log(selectedTable);

  useEffect(() => {
    getOrderList();
  }, []);
  console.log(isLoading);

  function handleSelect (item) {
    const isSelected = selectedTable.includes(item.order_id);
    if (isSelected) {
      setSelectedTable(selectedTable.filter((selectedItem) => selectedItem !== item.order_id));
    } else {
      setSelectedTable([...selectedTable, item.order_id]);
    }
  }
  return (
    <div className="flex flex-1 bg-slate-100">
      {isLoading && (
        <div className="w-full bg-red-400">
          <Loader />
        </div>
      )}
      <table class="table-fixed">
        <thead>
          <tr className="">
            <th></th>
            <th>Order ID</th>
            <th>Artist</th>
            <th>Year</th>
          </tr>
        </thead>
        <tbody>
          {orderList.map((item, index) => {
            const concatenatedAddress = [
              item.shippingaddressid.doorno,
              item.shippingaddressid.addressline1,
              item.shippingaddressid.addressline2,
              item.shippingaddressid.addressline3,
              item.shippingaddressid.city1,
              item.shippingaddressid.city2,
              item.shippingaddressid.city3,
              item.shippingaddressid.pinorzip,
            ]
              .filter((part) => part)
              .join(", ");
            return (
              <tr className="table-row">
                <input
                  checked={selectedTable.includes(item.order_id)}
                  id="whatsapp-checkbox"
                  onChange={() => handleSelect(item)}
                  type="checkbox"
                  value=""
                  className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                />
                <td>{item.order_id}</td>

                <td>{item.store_name}</td>
                <td>{item.status_new}</td>
                <td>{concatenatedAddress}</td>
                <td>{moment(item.created_time).format('MM/DD/YYYY')}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* {orderList && (
        <div className="w-full bg-green-300">
          <div className="w-full ">
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
            <h1>Hello</h1>
          </div>
          {orderList.map((item, index) => {
            return <div></div>;
          })}
        </div>
      )} */}
    </div>
  );
};

export default OrderListNew;
