import React from "react";
import Logo from "../../../assets/logo.svg";
import { homeScreenData } from "../../../components/common/DummyData";

const HomeScreenFooter = () => {
  return (
    <div className="mb-10 flex flex-col items-center justify-between bg-white">
      <div className="flex w-fit cursor-pointer space-x-2 rounded p-3">
        <img src={Logo} className="" alt="" />
        <a href="#" className="select-none text-xl font-semibold text-black">
          {/* Cart<span className="text-green-500 ">O</span>Cart */}
          {homeScreenData.title}
        </a>
      </div>
      <h6 className="cursor-auto text-center">
        Copyright © 2023 Jasmine eCommerce Solutions - All Rights Reserved.
      </h6>
      <div className="mb-10 mt-4 flex flex-1 items-center justify-center space-x-6 text-center md:mb-0 md:flex-row md:space-y-0">
        <div>
          <a href="/unsubscribe" className="hover:text-strongCyan">
            Account
          </a>
        </div>
        <div>
          <a href="/privacy" className="hover:text-strongCyan">
            Privacy Policy
          </a>
        </div>
        <div>
          <a href="/terms" className="hover:text-strongCyan">
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeScreenFooter;
