import {
  Alert,
  Backdrop,
  Box,
  Button,
  Fab,
  Modal,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ItemList from "./ItemList";
import { deleteMedia, orderStatus, postInvoice } from "../../../api/orderApi";
import "./OrdersContent.css";
import {
  Logout,
  CheckCircleOutline,
  WavingHand,
  AddCommentOutlined,
  DeleteOutline,
  UploadFile,
  Image,
  Delete,
} from "@mui/icons-material";
import Chat from "./Chat";
import { useDispatch } from "react-redux";
import { listOrders, postMessage } from "../../../redux/ordersSlice";
import { useMediaQuery } from "react-responsive";
import Loader from "./Loader";
import { statusList } from "../../../configData/Status";

function OrdersContent({ orders, id }) {
  const isDesktopOrMobile = useMediaQuery({
    query: "(min-width: 500px)",
  });
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const [chat, setChat] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [price, setPrice] = useState("");
  const [loader, setLoader] = useState(false);
  const [alertState, setAlertState] = useState(false);
  const [backDrop, setBackDrop] = useState("");

  const [expanded, setExpanded] = useState(false);
  const [summaryText, setSummaryText] = useState("Show Order Details");

  const delt = orders[0].orderid.deliverytype;

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(
    statusList[delt].findIndex(
      (item) => item.status === orders[0].orderid.status_new
    )
  );
  useEffect(() => {
    if (isDesktopOrMobile) {
      setChat();
    }
  }, [isDesktopOrMobile]);

  const postData = {
    msg: msg,
    orderid: id,
  };

  const fileInputRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleToggle = () => {
    setExpanded(!expanded);
    setSummaryText(expanded ? "Show Order Details" : "Hide Order Details");
  };

  const handleDeleteMedia = async (fileUrl) => {
    const filePath = fileUrl.match(/:\/\/[^\/]*(\/[^?#]*)/)[1];

    try {
      setLoader(true);
      const response = await deleteMedia(filePath);
      if (response) {
        dispatch(listOrders(postData.orderid));
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error, "op");
    }
  };

  const closeModal = () => {
    setOpen(false);
  };

  const sendMessage = async () => {
    if (msg !== "") {
      dispatch(postMessage(postData));
      dispatch(listOrders(postData.orderid));
      setMsg("");
    }
  };
  const handleTextFieldChange = (msg) => {
    setMsg(msg);
  };

  let totalItems = 0;
  let totalPrice = 0;

  for (const product of orders) {
    totalItems += product.quantity;
    totalPrice += product.quantity * product.price;
  }
  const userid = orders[0].orderid.userid.id;

  const statusHandler = async (status) => {
    if (orders[0].media_path !== null) {
      const statusState = await orderStatus(id, status, userid);
      setStatus(
        statusList[delt].findIndex(
          (item) => item.status === statusState.status_new
        )
      );
    } else {
      setAlertState(true);
    }
  };

  const toggleChat = () => {
    setChat(!chat);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      const validDocumentTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (
        validImageTypes.includes(fileType) ||
        validDocumentTypes.includes(fileType)
      ) {
        // Handle the selected file as needed
        setSelectedFile(selectedFile);
      } else {
        console.error(
          "Invalid file type. Please select an image (JPEG, PNG, GIF) or a PDF/Word document."
        );
        // You might want to show an error message to the user here
      }
    }
  };
  const handleUpload = async (status) => {
    if (selectedFile !== null && price !== "") {
      try {
        const resp = await postInvoice(
          selectedFile,
          orders[0].orderid.id,
          price
        );
        dispatch(listOrders(id));
      } catch (error) {
        console.log(error);
      }
      setLoader(true);
      // const statusState = await orderStatus(id, status, userid);
      // setStatus(
      //   statusList[delt].findIndex(
      //     (item) => item.status === statusState.status_new
      //   )
      // );
      // const statusState = await orderStatus(
      //   id,
      //   statusList[delt][2].status,
      //   userid
      // );
      // setStatus(statusState.status_new);
      setOpen(false);
      setLoader(false);
      setBackDrop(null);
    }
  };

  const button = (active) => {
    const button = {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "700",
      color: active ? "#FFFFFF" : "#3F3F3F ",
      height: "48px",
      width: "250px",
      borderColor: active ? "#FFFFFF" : "#3F3F3F ",
      backgroundColor: active ? "#51A275" : null,
      borderRadius: "8px",
      flexWrap: "nowrap",
      marginX: "10px",
      ":hover": {
        backgroundColor: "#51A275",
        borderColor: "#FFFFFF",
      },
    };
    return button;
  };

  const buttonMobile = (active) => {
    const buttonMobile = {
      fontSize: "12px",
      fontFamily: "Montserrat",
      fontWeight: "700",
      color: active ? "#FFFFFF" : "#3F3F3F ",
      height: "48px",
      borderColor: active ? "#FFFFFF" : "#3F3F3F ",
      backgroundColor: active ? "#51A275" : null,
      borderRadius: "8px",
      flexWrap: "nowrap",
      flexGrow: 1,
      marginX: "10px",
      ":hover": {
        backgroundColor: "#51A275",
        borderColor: "#FFFFFF",
      },
    };
    return buttonMobile;
  };
  const utcTimestamp = new Date(orders[0].orderid.createdtimestamp);
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const istTimestamp = utcTimestamp.toLocaleString("en-US", options);
  const disableNext =
    statusList[delt].length - 1 === status || !orders[0].media_paths
      ? true
      : false;

  console.log(statusList[delt], );

  return (
    <div className="font-montserrat w-full justify-center flex">
      {loader ? (
        <Loader />
      ) : (
        <div className={isDesktopOrMobile ? "container" : "container_mobile"}>
          {chat ? (
            <div className="w-full">
              <Chat
                onBack={() => {
                  setChat(!chat);
                }}
                sendMessage={sendMessage}
                onChange={handleTextFieldChange}
                messages={orders[0].orderid.messages}
              />
            </div>
          ) : (
            <>
              <div className={isDesktopOrMobile ? "ind" : "ind_mobile"}>
                <div className={isDesktopOrMobile ? "header" : "header_mobile"}>
                  <h2>Hi! {orders[0].orderid.storeid.name} Store</h2>
                  <Button variant="outlined" color="error">
                    <Logout fontSize="24" sx={{ mr: "8px" }} />
                    Logout
                  </Button>
                </div>

                <div
                  className={isDesktopOrMobile ? "content" : "content_mobile"}
                >
                  <div className="order_items">
                    <div
                      className={
                        isDesktopOrMobile ? "accordion" : "accordion_mobile"
                      }
                    >
                      <p>
                        Order summary: Order id #{orders[0].orderid.id} | Placed
                        at {istTimestamp} | {orders[0].orderid.deliverytype} |
                        {orders[0].orderid.paymenttype} | Total{" "}
                        {orders[0].orderid.totalamount}
                      </p>
                    </div>
                    <div className="order_items_header">
                      {isDesktopOrMobile && <h4>Ordered items</h4>}
                    </div>
                    <ItemList
                      orders={orders}
                      totalItems={totalItems}
                      totalPrice={totalPrice}
                    />
                    {/* <div
                      className={
                        isDesktopOrMobile
                          ? "order_status_btn mt-12"
                          : "order_status_btn_mobile mt-12"
                      }
                    >
                      <Stack
                        spacing={{ xs: 1, sm: 2 }}
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                      >
                        {statusList[delt].map((item, index) => {
                          const active = status.status === item.status;
                          return (
                            <Button
                              key={index}
                              startIcon={active && <CheckCircleOutline />}
                              variant={active ? "contained" : "outlined"}
                              // sx={status === item.status ? activeButton : inActiveButton}
                              sx={
                                !isDesktopOrMobile
                                  ? buttonMobile(active)
                                  : button(active)
                              }
                              onClick={() => {
                                statusHandler(item.status, index);
                              }}
                            >
                              {item.statusMessage}
                            </Button>
                          );
                        })}
                      </Stack>
                    </div> */}
                    <div className="flex items-center justify-center mt-5">
                      {orders[0].media_paths.length !== 0 ? (
                        <Button
                          startIcon={<Image />}
                          onClick={() => {
                            setBackDrop(orders[0].media_paths[0]);
                            
                          }}
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Montserrat",
                            fontWeight: "700",
                            color: "#FFFFFF",
                            height: "48px",
                            width: "250px",
                            borderColor: "#FFFFFF",
                            backgroundColor: "#51A275",
                            borderRadius: "8px",
                            flexWrap: "nowrap",
                            marginX: "10px",
                            ":hover": {
                              backgroundColor: "#51A275",
                              borderColor: "#FFFFFF",
                            },
                          }}
                          variant="contained"
                        >
                          View Invoice
                        </Button>
                      ) : (
                        <Button
                          startIcon={<UploadFile />}
                          onClick={() => {
                            setOpen(true);
                          }}
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Montserrat",
                            fontWeight: "700",
                            color: "#FFFFFF",
                            height: "48px",
                            width: "250px",
                            borderColor: "#FFFFFF",
                            backgroundColor: "#51A275",
                            borderRadius: "8px",
                            flexWrap: "nowrap",
                            marginX: "10px",
                            ":hover": {
                              backgroundColor: "#51A275",
                              borderColor: "#FFFFFF",
                            },
                          }}
                          variant="contained"
                        >
                          Upload Invoice
                        </Button>
                      )}
                    </div>
                    <div className="flex items-center align-middle  justify-around flex-1 mt-5 md:flex-row md:space-y-0 space-y-5 flex-col">
                      <h1>
                        Current Status :{" "}
                        {statusList[delt][status].statusMessage}
                      </h1>
                      <Button
                        disabled={disableNext}
                        startIcon={disableNext && <CheckCircleOutline />}
                        variant={disableNext ? "contained" : "outlined"}
                        // sx={status === item.status ? activeButton : inActiveButton}
                        sx={{
                          fontSize: "16px",
                          fontFamily: "Montserrat",
                          fontWeight: "700",
                          color: !disableNext ? "#FFFFFF" : "#3F3F3F ",
                          height: "48px",
                          width: "250px",
                          borderColor: !disableNext ? "#FFFFFF" : "#3F3F3F ",
                          backgroundColor: !disableNext ? "#51A275" : null,
                          borderRadius: "8px",
                          flexWrap: "nowrap",
                          marginX: "10px",
                          ":hover": {
                            backgroundColor: "#51A275",
                            borderColor: "#FFFFFF",
                          },
                        }}
                        onClick={() => {
                          statusHandler(statusList[delt][status + 1].status);
                        }}
                      >
                        {statusList[delt].length - 1 !== status
                          ? statusList[delt][status + 1].statusMessage
                          : "Order Completed"}
                      </Button>
                    </div>
                  </div>
                  {isDesktopOrMobile && (
                    <Chat
                      onBack={() => {
                        setChat(!chat);
                      }}
                      sendMessage={sendMessage}
                      onChange={handleTextFieldChange}
                      messages={orders[0].orderid.messages}
                    />
                  )}
                </div>
              </div>
              {!isDesktopOrMobile && (
                <div className="chat_toggle">
                  <div className="info">
                    <WavingHand
                      style={{
                        width: "16px",
                        height: "16px",
                        paddingRight: "13px",
                        maxRows: "8px",
                        color: "#bcaf29",
                      }}
                    />
                    <p>Chat with Customer</p>
                  </div>
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ width: "80%" }}
                    onClick={() => {
                      toggleChat();
                    }}
                  >
                    <AddCommentOutlined
                      fontSize="24"
                      sx={{
                        width: "16px",
                        height: "16px",
                        paddingRight: "13px",
                        maxRows: "8px",
                      }}
                    />
                    Start Conversation
                  </Button>
                </div>
              )}
            </>
          )}
          <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                minWidth: "250px",
                bgcolor: "#ffffff",
                boxShadow: "0px 1px 2px 0px #0000000F",
                padding: "24px",
                borderRadius: "13px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h6 className="modal-title">
                Ready for Pickup? Upload the invoice!
              </h6>
              {selectedFile === null ? (
                <Box
                  onClick={handleFileClick}
                  sx={{
                    bgcolor: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px #0000000F",
                    padding: "24px",
                    borderRadius: "13px",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "250px",
                    display: "flex",
                    flexDirection: "column",
                    marginY: "24px",
                    paddingX: "24px",
                    paddingY: "16px",
                  }}
                >
                  <svg
                    width="47"
                    height="46"
                    viewBox="0 0 47 46"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="3.5"
                      y="3"
                      width="40"
                      height="40"
                      rx="20"
                      fill="#F2F4F7"
                    />
                    <path
                      d="M20.167 26.3333L23.5003 23M23.5003 23L26.8337 26.3333M23.5003 23V30.5M30.167 26.9524C31.1849 26.1117 31.8337 24.8399 31.8337 23.4167C31.8337 20.8854 29.7816 18.8333 27.2503 18.8333C27.0682 18.8333 26.8979 18.7383 26.8054 18.5814C25.7187 16.7374 23.7124 15.5 21.417 15.5C17.9652 15.5 15.167 18.2982 15.167 21.75C15.167 23.4718 15.8632 25.0309 16.9894 26.1613"
                      stroke="#475467"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect
                      x="3.5"
                      y="3"
                      width="40"
                      height="40"
                      rx="20"
                      stroke="#F9FAFB"
                      strokeWidth="6"
                    />
                  </svg>
                  <input
                    type="file"
                    ref={fileInputRef}
                    className="file-input"
                    accept="image/*, application/pdf"
                    onChange={handleFileChange}
                  />
                  <h6 className="modal-description">
                    <span className="click_text">Click to upload</span> or drag
                    and drop{" "}
                  </h6>
                  <h6 className="modal-description">
                    PNG, JPG or GIF (max. 800x400px)
                  </h6>
                </Box>
              ) : (
                <Box
                  sx={{
                    bgcolor: "#FFFFFF",
                    boxShadow: "0px 1px 2px 0px #0000000F",
                    padding: "24px",
                    borderRadius: "13px",
                    alignItems: "center",
                    width: "250px",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    marginY: "24px",
                    paddingX: "24px",
                    paddingY: "16px",
                  }}
                >
                  <div className="selected_file">
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#F2F4F7"
                      />
                      <path
                        d="M19.3337 11.5131V14.2668C19.3337 14.6402 19.3337 14.8269 19.4063 14.9695C19.4702 15.0949 19.5722 15.1969 19.6977 15.2608C19.8403 15.3335 20.027 15.3335 20.4003 15.3335H23.154M23.3337 16.659V21.4668C23.3337 22.5869 23.3337 23.147 23.1157 23.5748C22.9239 23.9511 22.618 24.2571 22.2416 24.4488C21.8138 24.6668 21.2538 24.6668 20.1337 24.6668H15.867C14.7469 24.6668 14.1868 24.6668 13.759 24.4488C13.3827 24.2571 13.0767 23.9511 12.885 23.5748C12.667 23.147 12.667 22.5869 12.667 21.4668V14.5335C12.667 13.4134 12.667 12.8533 12.885 12.4255C13.0767 12.0492 13.3827 11.7432 13.759 11.5515C14.1868 11.3335 14.7469 11.3335 15.867 11.3335H18.0082C18.4974 11.3335 18.7419 11.3335 18.9721 11.3888C19.1762 11.4377 19.3713 11.5186 19.5502 11.6282C19.7521 11.7519 19.925 11.9249 20.2709 12.2708L22.3964 14.3962C22.7423 14.7421 22.9153 14.9151 23.0389 15.1169C23.1486 15.2959 23.2294 15.491 23.2784 15.695C23.3337 15.9252 23.3337 16.1698 23.3337 16.659Z"
                        stroke="#51A275"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <rect
                        x="2"
                        y="2"
                        width="32"
                        height="32"
                        rx="16"
                        stroke="#F9FAFB"
                        strokeWidth="4"
                      />
                    </svg>
                    <h6>{selectedFile.name.slice(0, 10)}</h6>
                    <h6>{Math.round(selectedFile.size / 1024)} KB</h6>
                    <div>
                      <DeleteOutline
                        onClick={() => {
                          // await handleDeleteMedia(orders[0].orderid.id);
                          setSelectedFile(null);
                        }}
                        sx={{
                          width: "20px",
                          height: "20px",
                          paddingRight: "13px",
                          maxRows: "8px",
                          color: "#",
                        }}
                      />
                    </div>
                  </div>
                </Box>
              )}

              <TextField
                fullWidth
                className="price_input"
                label="Bill Amount"
                variant="outlined"
                color="success"
                value={price}
                pattern="[0-9]*"
                type="number"
                onChange={(e) => {
                  setPrice(e.target.value);
                }}
              />
              {selectedFile && (
                <div className="confirm_button">
                  <Button
                    variant="contained"
                    sx={{ marginTop: "24px", backgroundColor: "#51A175" }}
                    onClick={() => {
                      handleUpload("OrderReadyForPickup");
                    }}
                  >
                    <CheckCircleOutline
                      fontSize="24"
                      sx={{
                        width: "16px",
                        height: "16px",
                        paddingRight: "13px",
                        maxRows: "8px",
                        color: "#",
                      }}
                    />
                    Confirm
                  </Button>
                </div>
              )}
            </Box>
          </Modal>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backDrop}
            onClick={() => {
              setBackDrop(null);
            }}
          >
            <div className="absolute top-2 left-2 flex-row space-x-2 flex md:space-y-2 md:space-x-0 md:flex-col">
              <Fab
                color="error"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteMedia(backDrop);
                  setBackDrop("");
                }}
              >
                <Delete />
              </Fab>
              <Fab
                color="info"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpen(true);
                }}
              >
                <UploadFile />
              </Fab>
            </div>
            <img src={backDrop} style={{ height: "80%" }} />
          </Backdrop>
        </div>
      )}
      <Snackbar
        open={alertState}
        autoHideDuration={6000}
        onClose={() => {
          setAlertState(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertState(false);
          }}
          severity="error"
          sx={{ width: "100%" }}
        >
          Upload invoice image or pdf to continue
        </Alert>
      </Snackbar>
    </div>
  );
}

export default OrdersContent;
