import React from "react";
import { homeScreenData } from "../../../components/common/DummyData";

const HomeScreenAbout = () => {
  return (
    <div
      id="homescreen_about"
      className="containers mx-auto flex flex-col justify-center px-4 py-2 text-center"
    >
      <h1 className="mx-2 self-center border-b-2 border-[#51A175]  text-4xl text-[#51A175]">
        {homeScreenData.aboutus.title}
      </h1>
      <div className="my-8 flex flex-col justify-between space-y-4 md:flex-row md:space-x-8 md:space-y-0">
        <div className="flex flex-1 flex-col justify-start">
          <h1 className="text-2xl text-[#51A175]">
            {homeScreenData.aboutus.subTitle1}
          </h1>
          <h1 className="text-lg text-black">
            {homeScreenData.aboutus.subTitle1Description}
          </h1>
        </div>
        <div className="flex flex-1 flex-col justify-start">
          <h1 className="text-2xl text-[#51A175]">
            {homeScreenData.aboutus.subTitle2}
          </h1>
          <h1 className="text-lg text-black">
            {homeScreenData.aboutus.subTitle2Description}
          </h1>
        </div>
      </div>

      {/* <div className="underline underline-offset-2"></div> */}
    </div>
  );
};

export default HomeScreenAbout;
