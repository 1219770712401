import { Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Loader from "../../Order/components/Loader";
import ItemList from "../../Order/components/ItemList";

const OrderDetail = ({ handleClose, loading, error, orders }) => {
  const order = orders[0];
  console.log(order, "asd");

  const [expanded, setExpanded] = useState(false);
  const utcTimestamp = new Date(order.orderid.createdtimestamp);
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const istTimestamp = utcTimestamp.toLocaleString("en-US", options);

  const [summaryText, setSummaryText] = useState("Show Order Details");
  const handleToggle = () => {
    setExpanded(!expanded);
    setSummaryText(expanded ? "Show Order Details" : "Hide Order Details");
  };

  let totalItems = 0;
  let totalPrice = 0;

  for (const product of orders) {
    totalItems += product.quantity;
    totalPrice += product.quantity * product.price;
  }
  return (
    <div className=" bg-white p-10 space-y-4 rounded-3xl h-5/6 mb-2 overflow-y-auto overflow-x-auto">
      <div className="flex justify-between w-full">
        <h1 className="text-[#51A275] font-montserrat font-medium text-lg">
          Order Detail
        </h1>
        <button onClick={handleClose}>
          <Close style={{ color: "black" }} />
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <Accordion
          disableGutters={true}
          expanded={expanded}
          onChange={handleToggle}
          sx={{
            width: "100%",
            backgroundColor: "#F4F8FA",
            borderRadius: "12px !important",
            marginBottom: "10px",
            "&:before": { height: "0 !important" },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Montserrat",
                fontWeight: "600",
              }}
            >
              {summaryText} for #{order.orderid.id}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingY: 0,
            }}
          >
            <div className="mb-3 space-y-2 flex flex-col">
              {[
                { label: "Order ID", value: `#${order.orderid.id}` },
                { label: "Order Placed at", value: istTimestamp },
                { label: "Order Type", value: order.orderid.deliverytype },
                {
                  label: "Payment Method",
                  value: order.orderid.paymenttype,
                },
                { label: "Total", value: order.orderid.totalamount },
              ].map((item, index) => (
                <div key={index} className="flex justify-between">
                  <p className="heading">{item.label}</p>
                  <p className="sub_heading">{item.value}</p>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      )}
      <ItemList
        orders={orders}
        totalItems={totalItems}
        totalPrice={totalPrice}
      />
      {order.media_path && <img src={order.media_path} alt="Order Media" />}
    </div>
  );
};

export default OrderDetail;
