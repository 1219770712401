import React from "react";
import { homeScreenData } from "../../../components/common/DummyData";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const HomeScreenFAQ = () => {
  const accordianStyle = {
    marginY: "10px",
    backgroundColor: "transparent",
    fontFamily: "Montserrat",
    "& .MuiTypography-root": {
      fontSize: "20px",
      fontFamily: "Montserrat",
      fontWeight: "800",
    },
  };

  return (
    <div id="homescreen_faq" className="bg-slate-50 py-8">
      <div className="containers flex-co mx-auto flex flex-col justify-center bg-slate-50 px-4 py-2 text-center">
        <h1 className="mx-2 self-center border-b-2 border-[#51A175]  text-4xl text-[#51A175]">
          {homeScreenData.faq.title}
        </h1>
        <h4 className="mt-3">
          {homeScreenData.faq.description[0]}
          <a href={`mailto:${homeScreenData.faq.email}`}>
            {homeScreenData.faq.description[1]}
          </a>
          {homeScreenData.faq.description[2]}
        </h4>
        {homeScreenData.faq.faqs.map((item) => {
          return (
            <Accordion elevation={0} sx={accordianStyle}>
              <AccordionSummary
                sx={{ borderWidth: 0 }}
                expandIcon={<ExpandMore />}
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <h1 className="font-montserrat text-lg font-normal">
                  {item.answer}
                </h1>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default HomeScreenFAQ;
