import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import { listOrders } from "../../redux/ordersSlice";
import { Alert, Box, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { postMessage } from "../../api/chatApi";
import OrdersListContent from "./components/OrdersContent";

function Orders() {
  const [msg, setMsg] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const ordersList = useSelector((state) => state.orders);
  const { error, loading, orders } = ordersList;
  useEffect(() => {
    dispatch(listOrders(id));
  }, []);

  const postData = {
    msg: msg,
  };
  return (
    <div
      className="flex flex-row"
      style={{ display: "flex", flexDirection: "row" }}
    >
      {loading ? (
        <div className="w-full">
          <Loader />
        </div>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        orders.length !== 0 && <OrdersListContent orders={orders} id={id} />
      )}
    </div>
  );
}

export default Orders;
