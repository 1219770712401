import React, { useEffect, useState } from "react";
import { findbyfranchise } from "../../api/storeApi";
import { useSelector } from "react-redux";
import Loader from "../Order/components/Loader";
import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const StoreList = () => {
  const loginSlice = useSelector((state) => state.login);
  const [storeList, setStoreList] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getStores = async () => {
    setIsLoading(true);
    const { result, error } = await findbyfranchise();
    if (result) {
      console.log(result);
      setStoreList(result);
      setIsLoading(false);
    } else if (error) {
      // setError(true);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getStores();
  }, []);
  console.log(storeList);
  const navigate = useNavigate();

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex-1 h-screen bg-slate-200">
          <h1 className="font-montserrat text-3xl">Store List</h1>
          <div className="">
            {storeList.map((item, index) => {
              return (
                <div
                  key={index}
                  className="flex space-x-5 align-middle items-center"
                >
                  <h1 className="font-montserrat text-xl">{index + 1}</h1>
                  <h1 className="font-montserrat text-xl">{item.name}</h1>
                  <ArrowForward
                    onClick={() => {
                      navigate(`/store/${item.id}`, {
                        state: {
                          storeDetails: item,
                        },
                      });
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreList;
