import React from "react";

const ProductManager = () => {
  return (
    <div className="">
      <div></div>
    </div>
  );
};

export default ProductManager;
