// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-heading-styles h2 {

    font-size: 1.5rem;

    line-height: 2rem;

    font-weight: 700;

    --tw-text-opacity: 1;

    color: rgb(31 41 55 / var(--tw-text-opacity))
}

.custom-heading-styles h3 {

    margin-left: 0.5rem;

    font-size: 1.25rem;

    line-height: 1.75rem;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(55 65 81 / var(--tw-text-opacity))
}

.custom-heading-styles p {

    margin-left: 2rem
}

.custom-heading-styles ul {

    margin-left: 3rem
}
`, "",{"version":3,"sources":["webpack://./src/screens/Privacy/PrivacyPolicy.css"],"names":[],"mappings":"AACE;;IAAA,iBAAuC;;IAAvC,iBAAuC;;IAAvC,gBAAuC;;IAAvC,oBAAuC;;IAAvC;AAAuC;;AAIvC;;IAAA,mBAA+C;;IAA/C,kBAA+C;;IAA/C,oBAA+C;;IAA/C,gBAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C;;AAI/C;;IAAA;AAAW;;AAIX;;IAAA;AAAY","sourcesContent":[".custom-heading-styles h2 {\n  @apply text-2xl font-bold text-gray-800;\n}\n\n.custom-heading-styles h3 {\n  @apply ml-2 text-xl font-semibold text-gray-700;\n}\n\n.custom-heading-styles p {\n  @apply ml-8;\n}\n\n.custom-heading-styles ul {\n  @apply ml-12;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
