import axios from "axios";
import { BASE_URL } from "./baseApi";

export const postMessage = async (orderid, postData) => {
  const response = await axios.post(`${BASE_URL}/postMessage/`, {
    message: postData.msg,
    order_id: orderid,
    direction: "OUT",
    message_type: "TEXT",
    status: "sent",
    sender_type: "user/store",
  });
  return response.data;
};
