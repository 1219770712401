import React, { useState } from "react";
import Title from "../../../components/common/Title";
import {
  ContactsIcon,
  ManageMediaIcon,
  ManageProductListIcon,
  StoreHomeIcon,
  StoreLogoutIcon,
  StoreManagementIcon,
  StoreStatIcon,
} from "../../../components/Svg";
import StoreSideBarButton from "./StoreSideBarButton";

const StoreSideBar = ({ selectedStoreMenu, handleStoreMenu }) => {
  console.log(selectedStoreMenu);

  const buttonsData = [
    // { id: "home", text: "Home", icon: StoreHomeIcon },
    // {
    //   id: "manageProductList",
    //   text: "Manage Product list",
    //   icon: ManageProductListIcon,
    // },
    {
      id: "AllOrders",
      text: "All Orders",
      icon: StoreManagementIcon,
    },
    { id: "Pickup", text: "Pickup", icon: ContactsIcon },
    { id: "Delivery", text: "Delivery", icon: ManageMediaIcon },
    { id: "Shipping", text: "Shipping", icon: StoreStatIcon },
  ];

  return (
    <div className="hidden h-screen w-90 flex-col justify-between py-5 font-montserrat text-sm md:flex ">
      {/* top side bar  */}
      <div className="mx-3 lg:items-start flex flex-col items-center ">
        {/* title */}
        <div className=" mb-4 flex py-2 min-w-36">
          <Title />
        </div>
        {/* top 2 buttons */}
        <div className="flex flex-1 flex-col max-w-max space-y-4 text-[#51A275]">
          {buttonsData.map((button) => (
            <StoreSideBarButton
              key={button.id}
              onClick={() => handleStoreMenu(button.id)}
              iconComponent={button.icon}
              color={selectedStoreMenu === button.id ? "#51A275" : "grey"}
              selected={selectedStoreMenu === button.id}
              text={button.text}
            />
          ))}
        </div>
      </div>
      {/* bottom side bar */}
      <div className="mx-5 lg:items-start flex flex-col items-center space-y-6">
        {/* break line */}
        <div className="flex-grow border-t border-gray-400"></div>
        {/* profile box */}
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center ">
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              alt=""
              className="hidden h-10 w-10 cursor-pointer rounded-full lg:flex"
            />
            <div className="ml-2">
              <h1 className="">Olivia Rhye</h1>
              <h1>Store Admin</h1>
            </div>
          </div>
          <button className="h-5 w-5">
            <StoreLogoutIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StoreSideBar;
