import React from "react";
import { homeScreenData } from "../../../components/common/DummyData";

const HomeScreenOffer = () => {
  return (
    <div id="homescreen_offer" className="">
      <div className="bg-slate-50 py-8">
        <div className="containers flex-co mx-auto flex flex-col justify-center px-4  text-center">
          <h1 className="mx-2 self-center border-b-2 border-[#51A175]  text-4xl text-[#51A175]">
            {homeScreenData.offer.title}
          </h1>
          <h1 className="my-4 self-start text-4xl font-semibold text-black">
            {homeScreenData.offer.storeHeading}
          </h1>
          <div className="flex flex-1 flex-col-reverse justify-between lg:flex-row">
            <div className="mx-4 text-left">
              {homeScreenData.offer.store.map((item) => {
                return (
                  <>
                    <h2 className="my-4 self-start text-xl font-medium text-black">
                      {item.heading}
                    </h2>
                    <ol className="list-decimal">
                      {item.points.map((item) => {
                        return <li>{item}</li>;
                      })}
                    </ol>
                  </>
                );
              })}
            </div>
            <img
              className="aspect-auto w-3/4 self-center rounded-xl lg:float-right lg:w-1/3"
              src="https://img1.wsimg.com/isteam/stock/5248/:/cr=t:0%25,l:25%25,w:50%25,h:100%25/rs=w:400,h:533,cg:true"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="containers flex-co mx-auto flex flex-col justify-center px-4 py-8 text-center">
        <h1 className="mx-2 self-center border-b-2 border-[#51A175]  text-4xl text-[#51A175]">
          {homeScreenData.offer.title}
        </h1>
        <h1 className="my-4 self-start text-4xl font-semibold text-black">
          {homeScreenData.offer.customerHeading}
        </h1>
        <div className="flex flex-1 flex-col-reverse justify-between lg:flex-row">
          <div className="mx-4 text-left">
            {homeScreenData.offer.customer.map((item) => {
              return (
                <>
                  <h2 className="my-4 self-start text-xl font-medium text-black">
                    {item.heading}
                  </h2>
                  <ol className="list-decimal">
                    {item.points.map((item) => {
                      return <li>{item}</li>;
                    })}
                  </ol>
                </>
              );
            })}
          </div>
          <img
            className="aspect-auto w-3/4 self-center rounded-xl lg:float-right lg:w-1/3"
            src="https://img1.wsimg.com/isteam/stock/877/:/cr=t:0%25,l:26.78%25,w:50%25,h:100%25/rs=w:400,h:533,cg:true"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default HomeScreenOffer;
