import React, { useEffect, useRef, useState } from "react";
import { deleteMedia, getOrders, postInvoice } from "../../api/orderApi";
import { useDispatch, useSelector } from "react-redux";
import "./OrdersList.css";
import {
  Backdrop,
  Box,
  Button,
  Fab,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
} from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  CalendarMonth,
  CheckCircleOutline,
  Close,
  Delete,
  DeleteOutline,
  Done,
  DownloadDone,
  Search,
  UploadFile,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Loader from "./components/Loader";
import moment from "moment";
import { concatenateAddress } from "../../utils/concatenateAddress";
import { clearOrders, listOrders } from "../../redux/ordersSlice";
import OrderDetail from "../Store/components/OrderDetail";
import { statusList } from "../../configData/Status";
// import { Document, Page } from "react-pdf";
import PdfSample from "../../assets/33_paymentReceipt.pdf";
import { getCurrencySymbol } from "../../configData/Currency";

const OrdersList = ({ loginSlice, selectedStoreMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTable, setSelectedTable] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");

  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [price, setPrice] = useState("");
  const [uploadId, setUploadId] = useState(false);
  const [backDrop, setBackDrop] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages);
  }

  const [currentFilter, setCurrentFilter] = useState("All");
  const ordersList = useSelector((state) => state.orders);

  const { error, loading, orders } = ordersList;

  const StyledTableCell = styled(TableCell)`
    ${({ hideonsmallscreen }) =>
      hideonsmallscreen && "@media (max-width: 600px) { display: none; }"}
  `;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  function handleSelect(item) {
    const isSelected = selectedTable.includes(item.order_id);
    if (isSelected) {
      setSelectedTable(
        selectedTable.filter((selectedItem) => selectedItem !== item.order_id)
      );
    } else {
      setSelectedTable([...selectedTable, item.order_id]);
    }
  }
  function handleSelectAll() {
    if (orderList.every((order) => selectedTable.includes(order.order_id))) {
      setSelectedTable([]);
    } else {
      setSelectedTable(orderList.map((item) => item.order_id));
    }
  }
  function orderListFilter(item) {
    setCurrentFilter(item);
  }
  function handleOrderDetail(id) {
    if (selectedRow !== id) {
      dispatch(listOrders(id));
      setSelectedRow(id);
    }
  }
  function handleClose() {
    dispatch(clearOrders());
    setSelectedRow("");
  }
  const orderListResponse = async () => {
    const ordersList = await getOrders(loginSlice.authtoken);
    setOrderList(ordersList);
    setIsLoading(false);
  };
  useEffect(() => {
    orderListResponse();
  }, []);

  const groupedData = orderList.reduce(
    (result, item) => {
      if (item.status_new === "OrderPickedUpByCustomer") {
        result.pickedUpOrders.push(item);
      } else if (item.status_new === "OrderInProcessingByDeliveryStaff") {
        result.ongoingOrders.push(item);
      } else if (item.status_new === "OrderSubmittedByCustomer") {
        result.newOrders.push(item);
      } else {
        result.readyForPickupOrders.push(item);
      }
      return result;
    },
    {
      pickedUpOrders: [],
      ongoingOrders: [],
      newOrders: [],
      readyForPickupOrders: [],
    }
  );
  console.log(groupedData);

  const tableItemsStyle = "";
  const tableHeaders = [
    "Order ID",
    "User ID",
    "Store Name",
    "Delivery Type",
    "Order Status",
    "Invoice",
    "Deliver/ship to",
    "Payment Method",
    "Payment Status",
    "Total Price",
    "Ordered Time",
    "Go to order",
  ];
  const filterHeaders = [
    "All",
    "New Orders",
    "Started Packing",
    "Ready For Pickup",
    "Order Completed",
  ];

  const filterData = (header) => {
    switch (header) {
      case "All":
        return orderList;
      case "New Orders":
        return groupedData.newOrders;
      case "Started Packing":
        return groupedData.ongoingOrders;
      case "Ready For Pickup":
        return groupedData.readyForPickupOrders;
      case "Order Completed":
        return groupedData.pickedUpOrders;
      default:
        return [];
    }
  };
  const fileInputRef = useRef(null);

  const closeModal = () => {
    setOpen(false);
  };
  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.type;
      const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      const validDocumentTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      if (
        validImageTypes.includes(fileType) ||
        validDocumentTypes.includes(fileType)
      ) {
        // Handle the selected file as needed
        setSelectedFile(selectedFile);
      } else {
        console.error(
          "Invalid file type. Please select an image (JPEG, PNG, GIF) or a PDF/Word document."
        );
        // You might want to show an error message to the user here
      }
    }
  };

  const handleUpload = async () => {
    if (selectedFile !== null && price !== "") {
      setIsLoading(true);
      const resp = await postInvoice(selectedFile, uploadId, price);

      setOpen(false);
      setBackDrop("");
      setSelectedFile(null);
      setPrice("");
      orderListResponse();
    }
  };
  const handleDeleteMedia = async (fileUrl) => {
    const filePath = fileUrl.match(/:\/\/[^\/]*(\/[^?#]*)/)[1];
    console.log(fileUrl, filePath);

    // try {
    //   setIsLoading(true);
    //   const response = await deleteMedia(filePath);
    //   if (response) {
    //     await orderListResponse();
    //     setIsLoading(false);
    //   }
    // } catch (error) {
    //   setIsLoading(false);
    //   console.log(error, "op");
    // }
  };
  console.log(selectedRow, "asd");

  return (
    <div className="h-screen flex-1 flex flex-row">
      <div className="w-full h-full ">
        <div className="w-full px-2 mb-2 py-2 justify-between flex overflow-x-auto ">
          {filterHeaders.map((item, index) => {
            return (
              <button
                key={index}
                className={`${
                  currentFilter === item && "text-black"
                } px-2 flex-nowrap`}
                onClick={() => {
                  setPage(0);
                  orderListFilter(item);
                }}
              >
                <h1 className="text-nowrap">{item}</h1>

                <div
                  className={`${
                    currentFilter === item && "border-[#51A275] border-b-2 my-1"
                  }`}
                />
              </button>
            );
          })}

          <button className="border rounded-xl px-2 py-1">
            <Search sx={{ color: "#51A275" }} />
          </button>
          <button className="border rounded-xl px-2 py-1">
            <CalendarMonth sx={{ color: "#51A275" }} />
          </button>
        </div>
        <div className="w-full px-2 mb-2 py-2 justify-between flex overflow-x-auto ">
          {statusList[selectedStoreMenu] &&
            statusList[selectedStoreMenu].map((item, index) => {
              return (
                <button key={index}>
                  <h1>{item.statusMessage}</h1>
                </button>
              );
            })}
        </div>
        {isLoading ? (
          <Loader className="w-full" />
        ) : (
          <div className="rounded-3xl overflow-hidden">
            {/* <Paper className="overflow-y-scroll flex flex-col h-3/4"> */}
            <TableContainer
              sx={{
                height: "100%",
                overflowY: "auto",
                // minHeight: "600px",
                backgroundColor: "white",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead
                  style={{
                    height: "44px",
                    backgroundColor: "#EAECF0",
                  }}
                >
                  <TableRow>
                    <StyledTableCell>
                      <input
                        checked={orderList.every((order) =>
                          selectedTable.includes(order.order_id)
                        )}
                        onChange={handleSelectAll}
                        type="checkbox"
                        className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                      />
                    </StyledTableCell>

                    {tableHeaders.map((item, index) => {
                      return (
                        <StyledTableCell
                          key={index}
                          sx={[{ padding: 0, textAlign: "center" }]}
                          align="left"
                          hideonsmallscreen={[""].includes(item).toString()}
                        >
                          <h1 className="font-montserrat">{item}</h1>
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filterData(currentFilter)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const currency = getCurrencySymbol(row.currency_code);
                      const advancePayment =
                        row.total_amount *
                        (parseInt(row.config.upfront_payment) / 100);

                      const paidAmount = row.payment_paths?.reduce(
                        (total, payment) => {
                          return total + parseFloat(payment.amount); // Convert amount to a float and sum it up
                        },
                        0
                      );
                      console.log(currency, advancePayment, paidAmount);

                      return (
                        <React.Fragment key={index}>
                          <TableRow
                            selected={selectedRow === row.order_id}
                            sx={{
                              cursor: "pointer",
                              backgroundColor:
                                index % 2 === 0 ? "#ffffff" : "#eaecf0",
                              // Add border for selected row
                              ...(selectedRow === row.order_id && {
                                backgroundColor: "#EFF6ED !important",
                                border: "2px solid #a26551", // Highlight border color
                              }),
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOrderDetail(row.order_id);
                            }}
                          >
                            <TableCell
                              sx={{ padding: 0, textAlign: "center" }}
                              align="left"
                            >
                              <input
                                checked={selectedTable.includes(row.order_id)}
                                id="whatsapp-checkbox"
                                onChange={(e) => {
                                  e.stopPropagation();
                                  handleSelect(row);
                                }}
                                type="checkbox"
                                value=""
                                className="h-5 w-5 rounded border-gray-300 bg-gray-100 accent-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                              />
                            </TableCell>
                            <TableCell align="left">
                              <h1 className="font-montserrat">
                                {row.order_id}
                              </h1>
                            </TableCell>
                            <TableCell align="left">
                              <h1 className="font-montserrat">{row.user_id}</h1>
                            </TableCell>
                            <TableCell align="left">
                              <h1 className="font-montserrat">
                                {row.store_name}
                              </h1>
                            </TableCell>
                            <TableCell align="center">
                              <h1 className="font-montserrat">
                                {row.delivery_type}
                              </h1>
                            </TableCell>
                            <TableCell align="center">
                              <h1 className="font-montserrat">
                                {row.status_new
                                  .replace(/([A-Z])/g, " $1")
                                  .trim()}
                              </h1>
                            </TableCell>
                            <TableCell align="center">
                              <h1 className="font-montserrat">
                                {row.media_path ? (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setBackDrop(row.media_path);
                                    }}
                                  >
                                    <DownloadDone />
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setOpen(true);
                                      setUploadId(row.order_id);
                                    }}
                                  >
                                    <UploadFile />
                                  </Button>
                                )}
                              </h1>
                            </TableCell>
                            <TableCell align="center">
                              <h1 className="font-montserrat min-w-44">
                                {concatenateAddress(row.shippingaddressid)}
                              </h1>
                            </TableCell>
                            <TableCell align="left">
                              <h1 className="font-montserrat">
                                {row.payment_method}
                              </h1>
                            </TableCell>
                            <TableCell align="center">
                              <h1 className="font-montserrat">
                                {/* {row.payment_path
                                  ? "Payment Done"
                                  : "Payment Pending"} */}
                              </h1>
                              {row.payment_paths ? (
                                <Done
                                  color="primary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setBackDrop(row.payment_path);
                                  }}
                                />
                              ) : (
                                <Close />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              <h1 className="font-montserrat">
                                {currency}
                                {row.total_amount}
                              </h1>
                            </TableCell>
                            <TableCell align="center">
                              <h1 className="font-montserrat">
                                {moment(row.created_time).format("DD-MM-YYYY")}
                              </h1>
                            </TableCell>
                            <TableCell align="left">
                              <ArrowForward
                                onClick={() => {
                                  navigate(`/orders/${row.order_id}`, {});
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={Boolean(backDrop)}
              onClick={() => {
                setBackDrop(null);
              }}
            >
              <div className="absolute top-2 left-2 flex-row space-x-2 flex md:space-y-2 md:space-x-0 md:flex-col">
                <Fab
                  color="error"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteMedia(backDrop);
                    setBackDrop("");
                  }}
                >
                  <Delete />
                </Fab>
                <Fab
                  color="info"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <UploadFile />
                </Fab>
              </div>
              <img src={backDrop} style={{ height: "80%" }} />
            </Backdrop>
            <div className="font-montserrat flex justify-between px-2 py-3 bg-white">
              <Button
                disabled={page + 1 === 1}
                onClick={(e) => {
                  handleChangePage(e, page - 1);
                }}
              >
                <ArrowBack />
              </Button>
              <h1>
                Page {page + 1} of{" "}
                {Math.ceil(filterData(currentFilter).length / rowsPerPage)}
              </h1>
              <Button
                disabled={
                  page + 1 >=
                  Math.ceil(filterData(currentFilter).length / rowsPerPage)
                }
                onClick={(e) => {
                  handleChangePage(e, page + 1);
                }}
              >
                <ArrowForward />
              </Button>
            </div>
            {/* </Paper> */}
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "250px",
            bgcolor: "#ffffff",
            boxShadow: "0px 1px 2px 0px #0000000F",
            padding: "24px",
            borderRadius: "13px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h6 className="modal-title">Ready for Pickup? Upload the invoice!</h6>
          {selectedFile === null ? (
            <Box
              onClick={handleFileClick}
              sx={{
                bgcolor: "#FFFFFF",
                boxShadow: "0px 1px 2px 0px #0000000F",
                padding: "24px",
                borderRadius: "13px",
                alignItems: "center",
                justifyContent: "center",
                width: "250px",
                display: "flex",
                flexDirection: "column",
                marginY: "24px",
                paddingX: "24px",
                paddingY: "16px",
              }}
            >
              <svg
                width="47"
                height="46"
                viewBox="0 0 47 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3.5"
                  y="3"
                  width="40"
                  height="40"
                  rx="20"
                  fill="#F2F4F7"
                />
                <path
                  d="M20.167 26.3333L23.5003 23M23.5003 23L26.8337 26.3333M23.5003 23V30.5M30.167 26.9524C31.1849 26.1117 31.8337 24.8399 31.8337 23.4167C31.8337 20.8854 29.7816 18.8333 27.2503 18.8333C27.0682 18.8333 26.8979 18.7383 26.8054 18.5814C25.7187 16.7374 23.7124 15.5 21.417 15.5C17.9652 15.5 15.167 18.2982 15.167 21.75C15.167 23.4718 15.8632 25.0309 16.9894 26.1613"
                  stroke="#475467"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <rect
                  x="3.5"
                  y="3"
                  width="40"
                  height="40"
                  rx="20"
                  stroke="#F9FAFB"
                  strokeWidth="6"
                />
              </svg>
              <input
                type="file"
                ref={fileInputRef}
                className="file-input"
                accept="image/*, application/pdf"
                onChange={handleFileChange}
              />
              <h6 className="modal-description">
                <span className="click_text">Click to upload</span> or drag and
                drop{" "}
              </h6>
              <h6 className="modal-description">
                PNG, JPG or GIF (max. 800x400px)
              </h6>
            </Box>
          ) : (
            <Box
              sx={{
                bgcolor: "#FFFFFF",
                boxShadow: "0px 1px 2px 0px #0000000F",
                padding: "24px",
                borderRadius: "13px",
                alignItems: "center",
                width: "250px",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                marginY: "24px",
                paddingX: "24px",
                paddingY: "16px",
              }}
            >
              <div className="selected_file">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="32"
                    height="32"
                    rx="16"
                    fill="#F2F4F7"
                  />
                  <path
                    d="M19.3337 11.5131V14.2668C19.3337 14.6402 19.3337 14.8269 19.4063 14.9695C19.4702 15.0949 19.5722 15.1969 19.6977 15.2608C19.8403 15.3335 20.027 15.3335 20.4003 15.3335H23.154M23.3337 16.659V21.4668C23.3337 22.5869 23.3337 23.147 23.1157 23.5748C22.9239 23.9511 22.618 24.2571 22.2416 24.4488C21.8138 24.6668 21.2538 24.6668 20.1337 24.6668H15.867C14.7469 24.6668 14.1868 24.6668 13.759 24.4488C13.3827 24.2571 13.0767 23.9511 12.885 23.5748C12.667 23.147 12.667 22.5869 12.667 21.4668V14.5335C12.667 13.4134 12.667 12.8533 12.885 12.4255C13.0767 12.0492 13.3827 11.7432 13.759 11.5515C14.1868 11.3335 14.7469 11.3335 15.867 11.3335H18.0082C18.4974 11.3335 18.7419 11.3335 18.9721 11.3888C19.1762 11.4377 19.3713 11.5186 19.5502 11.6282C19.7521 11.7519 19.925 11.9249 20.2709 12.2708L22.3964 14.3962C22.7423 14.7421 22.9153 14.9151 23.0389 15.1169C23.1486 15.2959 23.2294 15.491 23.2784 15.695C23.3337 15.9252 23.3337 16.1698 23.3337 16.659Z"
                    stroke="#51A275"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="2"
                    y="2"
                    width="32"
                    height="32"
                    rx="16"
                    stroke="#F9FAFB"
                    strokeWidth="4"
                  />
                </svg>
                <h6>{selectedFile.name.slice(0, 10)}</h6>
                <h6>{Math.round(selectedFile.size / 1024)} KB</h6>
                <div>
                  <DeleteOutline
                    onClick={() => {
                      // await handleDeleteMedia(orders[0].orderid.id);
                      setSelectedFile(null);
                    }}
                    sx={{
                      width: "20px",
                      height: "20px",
                      paddingRight: "13px",
                      maxRows: "8px",
                      color: "#",
                    }}
                  />
                </div>
              </div>
            </Box>
          )}

          <TextField
            fullWidth
            className="price_input"
            label="Bill Amount"
            variant="outlined"
            color="success"
            value={price}
            pattern="[0-9]*"
            type="number"
            onChange={(e) => {
              setPrice(e.target.value);
            }}
          />
          {selectedFile && (
            <div className="confirm_button">
              <Button
                variant="contained"
                sx={{ marginTop: "24px", backgroundColor: "#51A175" }}
                onClick={() => {
                  handleUpload();
                }}
              >
                <CheckCircleOutline
                  fontSize="24"
                  sx={{
                    width: "16px",
                    height: "16px",
                    paddingRight: "13px",
                    maxRows: "8px",
                    color: "#",
                  }}
                />
                Confirm
              </Button>
            </div>
          )}
        </Box>
      </Modal>
      {selectedRow && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={Boolean(selectedRow)}
          onClick={() => {
            setSelectedRow("");
          }}
        >
          <div
            className="flex h-full items-center overflow-auto"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <OrderDetail
              handleClose={handleClose}
              error={error}
              loading={loading}
              orders={orders}
            />
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default OrdersList;
