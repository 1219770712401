export const homeScreenData = {
  title: "Jasmine eCommerce Solutions",
  tagLine: "Design adds value faster than costs.",
  aboutus: {
    title: "About Us",
    subTitle1: "Customers",
    subTitle2: "Product",
    subTitle2Description:
      "CartOcart provides online services to consumers to place orders to buy their groceries and other household items from their favorite small- and medium-sized local grocery stores. In addition, CartOcart provides online services to the stores to manage their orders and facilitates delivery of the ordered items to their consumers.",
    subTitle1Description:
      "The target audience for CartOcart is public and small- and medium-sized local grocery stores. Specifically, we specialize in bottom up approach that is to start from small towns to cities.",
  },
  offer: {
    title: "What do we offer",
    storeHeading: "What do we offer to the stores",
    store: [
      {
        heading: "Offer online presence to small/medium level stores",
        points: [
          "Increase sales and customer base by going online",
          "Improve customer satisfaction with an easy shopping experience",
          "Streamline your business operations with efficient inventory managemen",
          "Manage your suppliers with integrated tools",
          "Analyze shopping patterns to optimize your product offerings",
        ],
      },
      {
        heading: "Our target stores in the order of interest",
        points: [
          "Support store owners keen on doing online business:",
          "Traditional store owners looking to do the online business",
          "New business owners looking to online shopping only but don’t have the ability to get it up and running quickly",
          "Support traditional mom and shop owners who are interested for online shopping",
          "Has some online interest but no tech knowledge.",
          "Has some online shop interest and tech knowledge but no ability to do it.",
          "Home based business owners who wants to expand the business but can’t invest in physical stores",
          "Small departmental stores without online presence.",
          "Support the store owners who doesn’t have experience in online business:",
          "Traditional mom and shop with no technological exposure.",
          "Support store owners keen on doing online business:",
          "Traditional mom and shop with some technological exposure with no keen interest on online shopping.",
        ],
      },
      {
        heading: "Ease of doing business",
        points: [
          "Analyze customer shopping patterns, and improve product offering based on their interest.",
          "Provide Dashboard report for easy sales reporting and monitoring, to assist on business status, sales by category/duration, current position of the business, help out on marketing improvements based on customer locality.",
        ],
      },
      {
        heading: "Ease of doing business",
        points: [
          "This assumes, price support in the product list is optional, and we are supporting the delivery.",
          "Product list is more of an approximate; allow to enter a product even it’s not in the product list.",
        ],
      },
    ],
    customerHeading: "What do we offer to the end customer",
    customer: [
      {
        heading:
          "Make order placing an easy experience and the overall shopping time is reduced",
        points: [
          "Complete the shopping as quick as possible",
          "Avoid wait time at the store lines to place order",
          "Order and delivery tracking notification are provided",
          "Manage the shopping history",
          "Deliver orders to the customers home",
        ],
      },
      {
        heading: "Find better products and better prices",
        points: [
          "Find better price for products that I usually buy",
          "Find better and new product that are available to buy",
          "Find new stores that are available to close to me",
          "Find product availability by store",
          "Find stores that are available to deliver even they are far away",
        ],
      },
      {
        heading: "Additional benefits",
        points: [
          "Reorder the frequently purchased items",
          "Order and delivery tracking on demand is provided",
          "Make repeat order",
        ],
      },
      {
        heading: "Other items",
        points: [
          "Identify and plan the monthly grocery budget",
          "Share feedback on store, product and purchase experience",
        ],
      },
    ],
  },
  faq: {
    title: "Frequently Asked Questions",
    email: "jasmecomsol@gmail.com",
    description: [
      "Please reach us at ",
      "jasmecomsol@gmail.com",
      " if you cannot find an answer to your question.",
    ],
    faqs: [
      {
        question: "How does CartOcart app work for customers?",
        answer:
          "CartOcart app connects customers with their favorite local grocery stores, allowing them to browse products, place orders, and receive deliveries—all through a user-friendly mobile app.",
      },
      {
        question: "How can local grocery stores partner with CartOcart?",
        answer:
          "Local store owners interested in joining our platform can contact us through our jasmecomsol@gmail.com. We offer comprehensive support to help stores get started online.",
      },
      {
        question: "How can I download the CartOcart app?",
        answer:
          "You can download the Grocery SOS app from the App Store (for iOS devices) or the Google Play Store (for Android devices). Simply search for 'Grocery SOS' and follow the installation instructions.",
      },
      {
        question: "What areas do you serve?",
        answer:
          "Currently, we serve [Specify the regions or locations]. We are continuously expanding our service areas, so stay tuned for updates!",
      },
      {
        question: "What payment methods are accepted?",
        answer: "Add an answer to this item.",
      },
      {
        question: "Is my personal information secure on CartOcart?",
        answer:
          "We take your privacy and security seriously. Your personal information is encrypted and protected according to industry standards. For more details, please review our Privacy Policy on our website. Feel free to customize these answers as needed to align with your app's specific features and policies.",
      },
    ],
  },
};
