import axios from "axios";
import { BASE_URL } from "./baseApi";

export const findbyfranchise = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}/findbyfranchise/?franchiseid=1`
    );

    return { result: response.data };
  } catch (error) {
    return { error: error };
  }
};
