import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Orders from "./screens/Order/Orders";
import { persistor, store } from "./redux/store/store";
import HomeScreen from "./screens/Home/HomeScreen";
import UnsubscribeScreen from "./screens/Subscribe/UnsubscribeScreen";
import LoginScreen from "./screens/login/LoginScreen";
import RequireAuth from "./components/RequireAuth";
import { PersistGate } from "redux-persist/integration/react";
import PrivacyPolicy from "./screens/Privacy/PrivacyPolicy";
import OrdersList from "./screens/Order/OrdersList";
import OrderManager from "./screens/Store/OrderManager";
import ProductManager from "./screens/Store/ProductManager";
import StoreList from "./screens/Store/StoreList";
import StoreDetails from "./screens/Store/StoreDetails";
import StoreScreen from "./screens/Store/StoreScreen";
import TermsAndConditions from "./screens/Terms/TermsAndConditions";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomeScreen />}></Route>
            <Route path="/login" element={<LoginScreen />}></Route>
            <Route path="/privacy" element={<PrivacyPolicy />}></Route>
            <Route path="/terms" element={<TermsAndConditions />}></Route>
            <Route
              path="/orderslist"
              element={<RequireAuth component={<OrdersList />} />}
            ></Route>
            <Route
              path="/store"
              element={<RequireAuth component={<StoreScreen />} />}
            ></Route>
            <Route
              path="store/ordermanager"
              element={<RequireAuth component={<OrderManager />} />}
            ></Route>
            <Route
              path="storelist"
              element={<RequireAuth component={<StoreList />} />}
            ></Route>
            <Route
              path="store/:storeId"
              element={<RequireAuth component={<StoreDetails />} />}
            ></Route>
            <Route
              path="store/productmanager"
              element={<RequireAuth component={<ProductManager />} />}
            ></Route>
            <Route
              path="/orders/:id"
              element={<RequireAuth component={<Orders />} />}
            ></Route>
            <Route path="/unsubscribe" element={<UnsubscribeScreen />}></Route>
            <Route path="*" element={<Navigate replace to="/" />}></Route>
          </Routes>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
