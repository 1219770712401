import React, { useEffect, useState } from "react";
import { useLocation, useParams, useRouteError } from "react-router-dom";
import StoreSideBar from "./components/StoreSideBar";
import { Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Loader from "../Order/components/Loader";

const StoreDetails = () => {
  const { storeId } = useParams();
  const location = useLocation();
  const { storeDetails } = location.state;
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    promotion: [],
    cart_limit: [],
    priceimageurl: storeDetails.config.priceimageurl,
    delivery_modes: storeDetails.config.delivery_modes,
    delivery_range: storeDetails.config.delivery_range,
    payment_method: storeDetails.config.payment_method,
    suppress_price: storeDetails.config.suppress_price,
    upfront_payment: storeDetails.config.upfront_payment,
    pickup_Locations: storeDetails.config.pickup_Locations,
    suppress_image: storeDetails.config.suppress_image,
    mandate_address_for_store_pickup:
      storeDetails.config.mandate_address_for_store_pickup,
    delivery_to_customer_address_instructions:
      storeDetails.config.delivery_to_customer_address_instructions,
  });

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      promotion: storeDetails.config.promotion,
      cart_limit: storeDetails.config.cart_limit,
      priceimageurl: storeDetails.config.priceimageurl,
      delivery_modes: storeDetails.config.delivery_modes,
      delivery_range: storeDetails.config.delivery_range,
      payment_method: storeDetails.config.payment_method,
      suppress_price: storeDetails.config.suppress_price,
      upfront_payment: storeDetails.config.upfront_payment,
      pickup_Locations: storeDetails.config.pickup_Locations,
      suppress_image: storeDetails.config.suppress_image,
      mandate_address_for_store_pickup:
        storeDetails.config.mandate_address_for_store_pickup,
      delivery_to_customer_address_instructions:
        storeDetails.config.delivery_to_customer_address_instructions,
    }));
    setIsLoading(false);
  }, []);

  const h1_style = "font-montserrat text-xl bg-slate-50";
  const prop_style = "font-montserrat text-lg font-semibold";
  const prop_contianer = "space-y-2 py-4";
  return isLoading ? (
    <Loader />
  ) : (
    <div className="flex h-screen overflow-hidden">
      {/* <StoreSideBar /> */}
      <div className="px-5 flex flex-col w-full overflow-auto">
        <div>
          <h1 className="font-montserrat text-3xl">Store Configuration</h1>
        </div>

        <div className={`${prop_contianer} `}>
          <div className="flex justify-between">
            <h1 className={`${h1_style} `}>Promotion</h1>
            <Button onClick={() => {}}>
              <Edit />
            </Button>
          </div>
          {data.promotion.map((promo, index) => (
            <div key={index}>
              <div className="flex items-center py-2">
                <p className={`${prop_style} `}>Description: </p>
                <p>{promo.promotion_desc}</p>
              </div>
              <div className="flex items-center py-2">
                <p className={`${prop_style} `}>Type: </p>
                <p>{promo.promotion_type}</p>
              </div>
              <div className="flex items-center py-2">
                <p className={`${prop_style} `}>Active: </p>
                <p>{promo.promotion_active ? "Yes" : "No"}</p>
              </div>
              <div className="flex items-center py-2">
                <p className={`${prop_style} `}>Promotion List Description: </p>
                <p>{promo.promotion_list_desc}</p>
              </div>
              <div className="flex items-center py-2">
                <p className={`${prop_style} `}>
                  Promotion Short Description:{" "}
                </p>
                <p>{promo.promotion_shortdesc}</p>
              </div>
            </div>
          ))}
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style} `}>Cart Limit</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          {data.cart_limit.map((limit, index) => (
            <div key={index} className="flex space-x-5">
              <p>{index + 1}</p>
              <p>Product ID: {limit.id}</p>
              <p>Limit: {limit.limit}</p>
            </div>
          ))}
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style} `}>Price Image URL</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <h1>{data.priceimageurl}</h1>
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Delivery Modes</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          {data.delivery_modes.map((mode, index) => (
            <div key={index} className="flex space-x-5">
              <p>{index + 1}</p>
              <p>Delivery Mode: {mode.delivery_mode}</p>
              <p>Payment Modes: {mode.payment_mode.join(", ")}</p>
              {/* Render other delivery mode details */}
            </div>
          ))}
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Delivery Range</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <p>{data.delivery_range}</p>
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Payment Method</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          {data.payment_method.map((method, index) => (
            <div key={index} className="flex space-x-5">
              <p>{index + 1}</p>
              <div>
                <p>Method: {method.method}</p>
                <p>URL :{method.image}</p>
              </div>
            </div>
          ))}
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Suppress Price</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <div className="space-x-5 flex">
            <p className={`${prop_style} `}>Do you want to suppress price: </p>
            <p>{data.suppress_price ? "Yes" : "No"}</p>
          </div>
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Upfront Payment</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <div className="space-x-5 flex">
            <p className={`${prop_style} `}>
              Amount to be paid before shipping:{" "}
            </p>
            <p>{data.upfront_payment}</p>
          </div>
        </div>

        <div>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Pickup Locations</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          {data.pickup_Locations.map((location, index) => (
            <div key={index} className="flex space-y-5 space-x-5 items-center">
              <p>{index + 1}</p>
              <div>
                <p>Note: {location.note}</p>
                <p>Address: {location.addressline1}</p>
              </div>
              {/* Render other pickup location details */}
            </div>
          ))}
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Suppress Product Image</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <div className="space-x-5 flex">
            <p className={`${prop_style} `}>Do you want to suppress Image: </p>
            <p>{data.suppress_image ? "Yes" : "No"}</p>
          </div>
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Pickup at Store Instruction</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <p>{data.pickup_at_store_instruction}</p>
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>Mandate Address for Store Pickup</h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <div className="space-x-5 flex">
            <p className={`${prop_style} `}>
              Make mandate address for store pickup:{" "}
            </p>
            <p>{data.mandate_address_for_store_pickup ? "Yes" : "No"}</p>
          </div>
        </div>

        <div className={`${prop_contianer}`}>
          <div className="flex justify-between">
            <h1 className={`${h1_style}`}>
              Delivery to Customer Address Instructions
            </h1>
            <Button>
              <Edit />
            </Button>
          </div>
          <p>{data.delivery_to_customer_address_instructions}</p>
        </div>
      </div>
    </div>
  );
};

export default StoreDetails;
