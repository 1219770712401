// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_us {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #fbfdfe;
  align-items: center;
  justify-content: center;
}

.content_us {
  margin: 2% 20% 2% 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 391px;
}
.container_us p {
  text-align: center;
  /* margin: 0; */
}

.heading_us {
  color: var(--Primary, #51a175);
  font-family: Montserrat;
  font-size: 50px;
  font-style: italic;
  font-weight: 700;
  line-height: 110%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.heading_us span {
  color: #252525;
  font-family: Montserrat;
  font-size: 48px;
  font-style: italic;
  font-weight: 700;
  line-height: 110%;
}
.details {
  color: #242424;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.button_us {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/screens/Subscribe/UnsubscribeScreen.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,kBAAkB;EAClB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".container_us {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n  background-color: #fbfdfe;\n  align-items: center;\n  justify-content: center;\n}\n\n.content_us {\n  margin: 2% 20% 2% 20%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  max-width: 391px;\n}\n.container_us p {\n  text-align: center;\n  /* margin: 0; */\n}\n\n.heading_us {\n  color: var(--Primary, #51a175);\n  font-family: Montserrat;\n  font-size: 50px;\n  font-style: italic;\n  font-weight: 700;\n  line-height: 110%;\n  margin-top: 20px;\n  margin-bottom: 10px;\n}\n\n.heading_us span {\n  color: #252525;\n  font-family: Montserrat;\n  font-size: 48px;\n  font-style: italic;\n  font-weight: 700;\n  line-height: 110%;\n}\n.details {\n  color: #242424;\n  text-align: center;\n  font-family: Montserrat;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n}\n.button_us {\n  color: #fff;\n  text-align: center;\n  font-family: Montserrat;\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
