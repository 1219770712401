import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  result: "",
  phone: "",
  authtoken: "",
  user: {
    userid: null,
    fname: "",
    lname: "",
    address: "",
    pendingorderstodisplay: null,
    storerange: null,
    preferences: null,
    updates: [],
  },
  loginState: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    login: (state, action) => {
      state.authtoken = action.payload.authtoken;
      state.result = action.payload.result;
      state.phone = action.payload.phone;
      state.user.userid = action.payload.user.userid;
      state.user.address = action.payload.user.address;
      state.user.fname = action.payload.user.fname;
      state.user.lname = action.payload.user.lname;
      state.user.pendingorderstodisplay =
        action.payload.user.pendingorderstodisplay;
      state.user.storerange = action.payload.user.storerange;
      state.user.preferences = action.payload.user.preferences;
      state.user.updates = action.payload.user.updates;
      state.loginState = true;
      console.log(state, "userstate");
    },
    preferenceUpdate: (state, action) => {
      console.log(action.payload, "pref");
      state.user.preferences = action.payload.preferences;
      state.user.updates = action.payload.updates;
    },
    logout: (state, action) => {
      console.log("logging out");
      return initialState;
    },
  },
  extraReducers: (builder) => {},
});

// export const {} = productSlice.actions;
export const { login, logout, preferenceUpdate } = loginSlice.actions;
export default loginSlice.reducer;
