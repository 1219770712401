// StoreButton.js
import React from "react";

const StoreSideBarButton = ({
  onClick,
  iconComponent: IconComponent,
  color,
  selected,
  text,
}) => (
  <button
    onClick={onClick}
    className={`flex w-full items-center rounded-lg px-2 py-2 align-middle ${
      selected && "bg-[#51A1751A]"
    }`}
  >
    <div className="h-5 w-5">
      <IconComponent color={selected ? "#51A275" : "grey"} />
    </div>
    <h1
      className={`ml-3 text-base font-semibold text-left ${
        selected ? "text-[#51A275]" : "text-gray-500"
      }`}
    >
      {text}
    </h1>
  </button>
);

export default StoreSideBarButton;
