import React, { useState } from "react";
import "./HomeScreen.css";
import HomeScreenHeader from "./components/HomeScreenHeader";
import HomeScreenAbout from "./components/HomeScreenAbout";
import HomeScreenOffer from "./components/HomeScreenOffer";
import HomeScreenFAQ from "./components/HomeScreenFAQ";
import HomeScreenFooter from "./components/HomeScreenFooter";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/loginSlice";

const HomeScreen = () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const port = process.env.REACT_APP_PORT;
  console.log(apiUrl, port);

  return (
    <div>
      <HomeScreenHeader />
      <HomeScreenAbout />
      <HomeScreenOffer />
      <HomeScreenFAQ />
      <HomeScreenFooter />
    </div>
  );
};

export default HomeScreen;
