import Logo from "../../assets/logo.svg";

import React from "react";

const Title = () => {
  return (
    <div className="flex w-fit cursor-pointer space-x-2 rounded transition-all duration-200 hover:scale-110 ">
      <img src={Logo} className="" alt="" />
      <a href="/" className="select-none text-xl font-semibold text-black">
        Cart<span className="text-green-500 ">O</span>Cart
        {/* {homeScreenData.title} */}
      </a>
    </div>
  );
};

export default Title;
