import React, { useState } from "react";
import StoreSideBar from "./components/StoreSideBar";
import OrdersList from "../Order/OrdersList";
import OrderListNew from "./components/OrderListNew";
import { useSelector } from "react-redux";
import { Refresh } from "@mui/icons-material";
import { CartOCartLogo } from "../../components/Svg";

const OrderManager = () => {
  const [selectedStoreMenu, setselectedStoreMenu] = useState("AllOrders");
  function handleStoreMenu(option) {
    setselectedStoreMenu(option);
  }

  const loginSlice = useSelector((state) => state.login);

  return (
    <div className="flex h-screen overflow-hidden">
      <StoreSideBar
        selectedStoreMenu={selectedStoreMenu}
        handleStoreMenu={handleStoreMenu}
      />
      <div className="px-5 flex flex-col w-full bg-slate-200 overflow-auto">
        <CartOCartLogo />
        <div className="flex justify-between items-center w-full h-1/4 my-3">
          <div className="flex items-center space-x-3">
            <span>
              <h1 className="font-montserrat text-3xl">Home</h1>
              <h1 className="font-montserrat text-lg">All Orders</h1>
            </span>
            <button className="flex md:hidden">
              <Refresh />
            </button>
          </div>
          <button className="hidden  md:flex">
            <Refresh />
            <h1>Refresh Page</h1>
          </button>
        </div>
        <div className="flex-1">
          <OrdersList
            loginSlice={loginSlice}
            selectedStoreMenu={selectedStoreMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderManager;
